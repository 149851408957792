// src/store/services/events.js
import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'
import toastr from 'toastr'

class Event extends BaseModel {
    constructor(data, options) {
        super(data, options)
    }
    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'Event'
    // Define default properties here
    static instanceDefaults() {
        return {
            id: '',
            name: '',
            phone: ''
        }
    }
}
const servicePath = 'events'
const servicePlugin = makeServicePlugin({
    Model: Event,
    addOnUpsert: true,
    service: feathersClient.service(servicePath),
    handleEvents: {
        created: (item, { model, models }) => {
            console.log(item);
            toastr.options = {
                "closeButton": false,
                "debug": false,
                "newestOnTop": false,
                "progressBar": false,
                "positionClass": "toast-bottom-center",
                "preventDuplicates": false,
                "onclick": null,
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "5000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };
            toastr.warning('<div class="d-flex align-center mb-4">' +
                '                  <div class="reg-wrapper">' +
                '                    <span class="registration-ui" style="color: black;">'+item.best_ref+'</span>' +
                '                  </div>' +
                '                </div>')
            // If true, the new item will be stored.
            return true
        }
    },

    servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
})

export default servicePlugin
