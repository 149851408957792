/*
 * ============================
 * File: index-old.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import authenticate from '@/auth/authenticate';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/app/Index'),
      beforeEnter: authenticate,
      redirect: '/app/dashboard',
      children: [
        {
          path: '/app',
          component: () => import('@/views/app/dashboard/Index'),
          children: [
            {
              path: 'dashboard',
              name: 'dashboard',
              component: () => import('@/views/app/Devices'),
            },
            {
              path: 'overview',
              name: 'overview',
              component: () => import('@/views/app/dashboard/Dashboard'),
            },
            {
              path: 'jobsheets',
              name: 'jobsheets',
              component: () => import('@/views/app/JobSheets')
            },
            {
              path: 'archivedjobsheets',
              name: 'archivedjobsheets',
              component: () => import('@/views/app/ArchivedJobSheets')
            },
            {
              path: 'devices',
              name: 'devices',
              component: () => import('@/views/app/Devices')
            },
            {
              path: 'archiveddevices',
              name: 'archiveddevices',
              component: () => import('@/views/app/ArchivedDevices')
            },
            {
              path: 'adddevice',
              name: 'adddevice',
              component: () => import('@/views/app/AddDevices')
            },
            {
              path: 'warehouses',
              name: 'warehouses',
              component: () => import('@/views/app/Warehouses')
            },
            {
              path: 'addwarehouse',
              name: 'addwarehouse',
              component: () => import('@/views/app/AddWarehouse.vue')
            },
            {
              path: 'partners',
              name: 'partners',
              component: () => import('@/views/app/Partners')
            },
            {
              path: 'rams',
              name: 'rams',
              component: () => import('@/views/app/Rams')
            },
            {
              path: 'stockmoves',
              name: 'stockmoves',
              component: () => import('@/views/app/StockMoves')
            },
            {
              path: 'addpartner',
              name: 'addpartner',
              component: () => import('@/views/app/AddPartner')
            },
            {
              path: 'adddevice',
              name: 'adddevice',
              component: () => import('@/views/app/AddDevices')
            },
            {
              path: 'stock',
              name: 'stock',
              component: () => import('@/views/app/DeviceStock')
            },
            {
              path: 'meters',
              name: 'meters',
              component: () => import('@/views/app/Meters')
            },
            {
              path: 'vocomissionings',
              name: 'vocomissionings',
              component: () => import('@/views/app/VOForms.vue')
            },
            {
              path: 'archivedvocomissionings',
              name: 'archivedvocomissionings',
              component: () => import('@/views/app/ArchivedVOForms.vue')
            },
            {
              path: 'vosurveys',
              name: 'vosurveys',
              component: () => import('@/views/app/VOSurveys.vue')
            },
            {
              path: 'voniceics',
              name: 'voniceics',
              component: () => import('@/views/app/VONiceics.vue')
            },
            {
              path: 'deviceview/:id',
              name: 'deviceview',
              component: () => import('@/views/app/DeviceView')
            },
            {
              path: 'meterview/:id',
              name: 'meterview',
              component: () => import('@/views/app/MeterView')
            },
            {
              path: 'vosurvey/:id',
              name: 'vosurvey',
              component: () => import('@/views/app/VOSurveyView.vue')
            },
            {
              path: 'voniceic/:id',
              name: 'voniceic',
              component: () => import('@/views/app/VONiceicView.vue')
            },
            {
              path: 'vocomissioning/:id',
              name: 'vocomissioning',
              component: () => import('@/views/app/VOFormView.vue')
            },
            {
              path: 'jobsheetview/:id',
              name: 'jobsheetview',
              component: () => import('@/views/app/JobSheetView')
            },
            {
              path: 'warehouseview/:id',
              name: 'warehouseview',
              component: () => import('@/views/app/WarehouseView')
            },
            {
              path: 'occupancy',
              name: 'occupancy',
              component: () => import('@/views/app/Occupancy')
            },
            //TODO: These dont have functioning CRUD
            {
              path: 'sysadmin',
              beforeEnter: '', //TODO: Check for admin permission somehow and reject otherwise ?
              component: () => import('@/views/app/admin/Index'),
              children: [
                {
                  name: 'carpark-list',
                  path: 'carparks',
                  meta: {
                    breadcrumb: [{ name: 'Car Parks' }],
                  },
                  component: () => import('@/views/app/admin/carparks/Index'),
                },
                {
                  name: 'carpark-create',
                  path: 'carparks/new',
                  component: () => import('@/views/app/admin/carparks/Create'),
                  meta: {
                    breadcrumb: [{ name: 'Car Parks' }, { name: 'Create'}],
                  },
                },
                {
                  name: 'carpark-edit',
                  path: 'carparks/:id',
                  component: () => import('@/views/app/admin/carparks/Edit'),
                  meta: {
                    breadcrumb: [{ name: 'Car Parks' }, { name: 'Edit'}],
                  },
                },
                {
                  name: 'carpark-view',
                  path: 'carparks/view/:id',
                  component: () => import('@/views/app/admin/carparks/View'),
                  meta: {
                    breadcrumb: [{ name: 'Car Parks' }, { name: 'View'}],
                  },
                },
                {
                  path: 'carParkOverrides',
                  name: 'carParkOverrides',
                  meta: {
                    breadcrumb: [{ name: 'Car Parks' }, { name: 'Index' }],
                  },
                  component: () => import('@/views/app/carparkoverrides/CarParkOverride'),
                },
                {
                  path: 'sensors',
                  name: 'sensors',
                  meta: {
                    breadcrumb: [{ name: 'Sensors' }],
                  },
                  component: () => import('@/views/app/admin/sensors/Index'),
                  children: [
                    {
                      name: 'create-sensor',
                      path: 'sensors/create',
                      component: () => import('@/views/app/admin/sensors/Create'),
                      meta: {
                        breadcrumb: [{ name: 'Sensors' }, { name: 'Create'}],
                      },
                    },
                    {
                      name: 'edit-sensor',
                      path: 'sensors/:id',
                      component: () => import('@/views/app/admin/sensors/Edit'),
                      meta: {
                        breadcrumb: [{ name: 'Sensors' }, { name: 'Edit'}],
                      },
                    },
                    {
                      name: 'view-sensor',
                      path: 'sensors/view/:id',
                      component: () => import('@/views/app/admin/sensors/View'),
                      meta: {
                        breadcrumb: [{ name: 'Sensors' }, { name: 'View'}],
                      },
                    }
                  ]
                },
                {
                  path: 'signs',
                  name: 'signs',
                  meta: {
                    breadcrumb: [{ name: 'Sign' }, { name: 'Index' }],
                  },
                  component: () => import('@/views/app/admin/signs/Sign'),
                },
                {
                  path: 'organisations',
                  name: 'organisations',
                  meta: {
                    breadcrumb: [{ name: 'Organisations' }, { name: 'Index' }],
                  },
                  component: () => import('@/views/app/admin/organisations/Organisation'),
                },
                {
                  path: 'sites',
                  name: 'sites',
                  meta: {
                    breadcrumb: [{ name: 'Sites' }, { name: 'Index' }],
                  },
                  component: () => import('@/views/app/admin/sites/Site'),
                },
                {
                  path: 'sensortypes',
                  name: 'sensortypes',
                  meta: {
                    breadcrumb: [{ name: 'Sensor Types' }, { name: 'Index' }],
                  },
                  component: () => import('@/views/app/admin/sensortypes/SensorType'),
                },
                {
                  path: 'signtypes',
                  name: 'signtypes',
                  meta: {
                    breadcrumb: [{ name: 'Sign Types' }, { name: 'Index' }],
                  },
                  component: () => import('@/views/app/admin/signtypes/SignType'),
                },
              ]
            },
          ],
        }
      ]
    },
    {
      path: '/map',
      name: 'map',
      component: () => import('@/views/app/auth/Login'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/app/auth/Login'),
    },
    {
      path: '*',
      name: '404',
      component: () =>  import("@/views/error/404")
    }
  ],
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
