<template>
  <router-view />
</template>

<script>
import { models } from 'feathers-vuex'

export default {
  name: "App",
  mounted: function() {
    this.login();
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Emissis Field Service",
      // all titles will be injected into this template
      titleTemplate:
        "%s | Emissis Field Service",
    };
  },
  methods: {
    getRouterParams() {
    },
    login() {
      //TODO: verify this is in the right place and handle error / redirect to login.
      this.$store.dispatch('auth/authenticate').catch(error => {
        if (!error.message.includes('Could not find stored JWT')) {
          console.error(error)
        }
      })
    }
  },
  created() {
    this.getRouterParams();
  },
};
</script>
