import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import authData from './modules/authData'
import themeConfig from './modules/themeConfig'
import verticalSidebar from './modules/verticalSidebar'
import { FeathersVuex } from '../feathers-client'
import auth from './store.auth'

// Load Vuex
Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context(
    // The path where the service modules live
    './services',
    // Whether to look in subfolders
    false,
    // Only include .js files (prevents duplicate imports`)
    /\.js$/
)
const servicePlugins = requireModule
    .keys()
    .map(modulePath => requireModule(modulePath).default)

// Create store
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [...servicePlugins, auth],
  modules: {
    largeSidebar,
    compactSidebar,
    authData,
    verticalSidebar,
    themeConfig
  },
})
